
<template>
    <div class="ms-auto"><a href="javascript:;" data-bs-toggle="modal" @click="limpiarInputs()"  data-bs-target="#agregarModal" class="btn btn-light radius-30 mt-2 mt-lg-0"><i class="bx bxs-plus-square"></i>Nuevo Pago</a></div>
    <!-- Button trigger modal -->
    <!-- Modal -->
    <div class="modal fade"  id="agregarModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content bg-dark">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Nuevo Pago</h5>
                    <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <div class="col-sm-2">
                            <h6 class="mb-0">Buscar Por</h6>
                        </div>
                        <div class="col-sm-8">
                            <input ref="inputInModal" placeholder="Buscar por #Venta, Nit del Cliente, o Nombre del Cliente" type="text" id="nit"  class="form-control" v-model="busquedaCredito"  />
                        </div>
                        <div class="col-sm-2">
                            <button type="button" @click="getVentasCreditoPendientes()" class="btn btn-primary">Buscar Crédito</button>
                        </div>
                    </div>
                    <div v-if="ventasEncontradas" class="row mb-3">
                        <div class="col-sm-2">
                            <h6 class="mb-0">Ventas al Crédito Encontradas:</h6>
                        </div>
                        <div  class="col-sm-10">
                            <div class="row">
                                <div class="col-sm-3 mb-3"  v-for="item in ventasEncontradas" :key="item.id">
                                    <button  type="button" class="btn btn-altura position-relative me-lg-5 w-100"
                                        :class="{'btn-outline-success': boton_click !== item.id, 'btn-success': boton_click === item.id}"
                                        @click="handleClick(item.id, item.numero_venta)">
                                        <div>
                                            Venta #: {{item.numero_venta}}
                                        </div>
                                        <div>
                                            Nombre: {{item.nombre_venta}}
                                        </div>
                                        <div>
                                            Pendiente: {{ $filters.currencyGTQ(item.saldo_pendiente) }}
                                        </div>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-2">
                            <h6 class="mb-0">Monto</h6>
                        </div>
                        <div class="col-sm-10">
                            <input type="number" id="monto"  class="form-control" v-model="pago.monto"  />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    <button type="button" @click="crearPago()" class="btn btn-primary">Nuevo Pago</button>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.btn-altura {
    line-height: 1.5; /* Ajusta este valor según tus necesidades */
    padding: 10px 20px;
}
</style>
<script>
    import  Cookies from 'js-cookie';
    //import Select2 from 'vue3-select2-component';
    //import $ from 'jquery';
    //let select2 = require('@/assets/plugins/select2/js/select2.min.js');
    //import * as mykey from '../assets/js/mykey.js'
    import $ from 'jquery';
    let notificaciones = require('@/assets/plugins/notifications/js/notification-custom-script.js');
    //import VueNextSelect from 'vue-next-select';
    import '@vuepic/vue-datepicker/dist/main.css';
    console.log(Cookies.get());
    export default {
        name: 'agregar_pago_component',
        components:{
            //'vue-select': VueNextSelect,
        },
    setup() {
    },
    data() {
        return{
            //Globales:
            pago_creada: false,
            id_pago_creada: '',
            URL_TENANT: '',
            cliente: null,
            empresaData: [0],
            usuariosData: [0],
            ventasCreditoPendientes: [0],
            ventasEncontradas: [],
            focusInput: null,
            boton_click: null,
            userData: {},
            busquedaCredito: '',
            pago: {
                venta: '',
                monto: ''
            },
            condicion_nit: 0,
            }
        },

    computed: {
    },
    watch:{
    },
    methods: {
        async getUserData(){
            const response = await fetch(this.URL_TENANT+'/api/user/');
            this.userData = await response.json();
        },
        handleClick(id, numero_venta) {
            this.boton_click = id;
            notificaciones.round_info_noti($.msg='Venta #'+ numero_venta+' Seleccionada');

        },
        async limpiarInputs(){
            this.ventasEncontradas = [],
            this.getVentasCreditoPendientes();
            this.pago_creada = false;
        },
        //Crear Venta:
        async crearPago(){
            try{
                if(this.pago.monto == '' || this.boton_click == null){
                    notificaciones.round_error_noti($.msg='Debe Elegir Crédito y Colocar el Monto a Pagar');
                }else{

                const formData = new FormData();
                formData.append("venta_credito", this.boton_click);
                formData.append("monto", this.pago.monto);
                formData.append("caja", this.userData[0].caja_del_dia.id);

                const response = await fetch(this.URL_TENANT+'/api/pago_credito/',{
                method: 'POST',
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },body: formData})
                if(response.ok){
                    const data = await response.json();
                    this.id_pago_creada = data.id;
                }
                notificaciones.round_success_noti($.msg='Pago Creado Exitosamente');
                //document.getElementById('agregarModal').click();
                document.getElementById('close').click();
                this.pago_creada = true;
                }
                }catch(error){
                    console.log(error);
                    notificaciones.round_error_noti($.msg='Error, algo salió mal');
                }
        },
        // Llenado:
        async getEmpresaData() {
            const response = await fetch(this.URL_TENANT+'/api/empresa/');
            this.empresaData = await response.json();
        },
        async getVentasCreditoPendientes() {
            
            const response = await fetch(this.URL_TENANT+'/api/venta_credito_no_pagados/?buscar='+this.busquedaCredito);
            this.ventasEncontradas = await response.json();
        },
        },
mounted() {
    var myModalEl = document.getElementById('agregarModal')
        myModalEl.addEventListener('shown.bs.modal', () => {
            this.$nextTick(() => {
                this.$refs.inputInModal.focus();
                this.$refs.inputInModal.select();
            });
        });
    },
beforeUnmount() {
    var myModalEl = document.getElementById('agregarModal')
    myModalEl.removeEventListener('shown.bs.modal', () => {
        this.$nextTick(() => {
            this.$refs.inputInModal.focus();
        });
    });
},
async created() {
        // Fetch tasks on page load
    const result = window.location.origin;
    this.URL_TENANT = result;
    this.getEmpresaData();
    this.getUserData();
    },
}
    </script>